import React from 'react';

import Main from './pages/main-page/main';

import './app.css?ver=1.1';

function App() {
	return (
		<div className="wrapper-app">
			<Main />
		</div>
	);
}

export default App;

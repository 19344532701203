import React, {useEffect, useState, useLayoutEffect} from 'react';
import Modal from 'react-modal';
import './main.css?ver=1.2';

function useWindowSize() {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize({width: window.innerWidth, height: window.innerHeight});
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
}

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

function Main() {
	const {width} = useWindowSize();
	const [name, setName] = useState('');
	const [code, setCode] = useState('');
	const [date, setDate] = useState('');
	const [category, setCategory] = useState('');
	const [exName, setExName] = useState('');
	const [isExamStarted, setIsExamStarted] = useState(false);
	const [errorClasses, setErrorClasses] = useState({
		errorDate: '',
		errorCategory: '',
		errorEx: '',
		errorCode: '',
		errorNameC: ''
	});
	const [isOpenModal, setIsOpenModal] = useState(false);

	const startChestionar = () => {
		if (name.length !== 0 && code.length !== 0 && date.length !== 0 && category.length !== 0 && exName.length !== 0) {
			setIsExamStarted(true);
		} else {
			if (name.length === 0) {
				setErrorClasses(currentState => ({...currentState, errorNameC: 'error-input'}));
			}
			if (code.length === 0) {
				setErrorClasses(currentState => ({...currentState, errorCode: 'error-input'}));
			}
			if (date.length === 0) {
				setErrorClasses(currentState => ({...currentState, errorDate: 'error-input'}));
			}
			if (category.length === 0) {
				setErrorClasses(currentState => ({...currentState, errorCategory: 'error-input'}));
			}
			if (exName.length === 0) {
				setErrorClasses(currentState => ({...currentState, errorEx: 'error-input'}));
			}
		}
	};

	const saveResult = () => {
		setIsOpenModal(true);
	};

	const closeModal = () => {
		setIsOpenModal(false);
	};

	const printResult = async () => {
		await closeModal();
		if (name.length !== 0 && code.length !== 0) {
			window.print();
		}
		// setIsExamStarted(false);
	};

	useEffect(() => {
		if (name.length !== 0) {
			setErrorClasses(currentState => ({...currentState, errorNameC: ''}));
		}
		if (code.length !== 0) {
			setErrorClasses(currentState => ({...currentState, errorCode: ''}));
		}
		if (date.length !== 0) {
			setErrorClasses(currentState => ({...currentState, errorDate: ''}));
		}
		if (category.length !== 0) {
			setErrorClasses(currentState => ({...currentState, errorCategory: ''}));
		}
		if (exName.length !== 0) {
			setErrorClasses(currentState => ({...currentState, errorEx: ''}));
		}
	}, [name, code, date, exName, category]);

	return (
		<div className="wrapper">
			<header>
				<div className="header">
					<div className="title">
						Școala de conducători auto <br />
						<span className="school-name">S.C. Teo Drive Defensive S.R.L</span> <br />
					</div>
					<span className="categories">Categoriile B, C, CE, D</span>
					<div className="address">Strada Mihail Kogălniceanu nr.25A, Jud. Botoșani, Mun. Botoșani</div>
					<div className="director">
						Director Teodorescu Viorel
						<span className="tel">+40 740 553 021</span>
					</div>
				</div>
				<div className="divider"></div>
				<div className="data-wrapper">
					<span className="label">Dată examinare</span>
					<input
						id="data-input"
						className={errorClasses.errorDate}
						type="text"
						placeholder="DD.MM.YYYY"
						onChange={event => setDate(event.target.value)}
					/>
					<span className="label row2">Categoria</span>
					<input
						id="category-input"
						className={errorClasses.errorCategory}
						type="text"
						placeholder="B/C/CE/D"
						onChange={event => setCategory(event.target.value)}
					/>
				</div>
				<div className="examinator-wrapper">
					<span className="label">Examinator</span>
					<input
						id="name-ex-input"
						className={errorClasses.errorEx}
						type="text"
						placeholder="Examinator"
						onChange={event => setExName(event.target.value)}
					/>
				</div>
				<div className="cursant-wrapper">
					<div className="row1">
						<span className="label">Nr. RNC</span>
						<input
							id="nr-input"
							className={errorClasses.errorCode}
							type="text"
							placeholder="00000000"
							onChange={event => setCode(event.target.value)}
						/>
					</div>
					<div className="row2">
						<span className="label">Cursant</span>
						<input
							id="name-input"
							className={errorClasses.errorNameC}
							type="text"
							placeholder="Cursant"
							onChange={event => setName(event.target.value)}
						/>
					</div>
				</div>
				<div className="sign-wrapper">
					<div className="ex-sign">
						<span className="label">Semnătură examinator</span>
						<input id="sign-input" type="text" placeholder="" disabled />
					</div>
					<div className="cursant-sign row2">
						<span className="label">Semnătură cursant</span>
						<input id="sign-input" type="text" placeholder="" disabled />
					</div>
				</div>
			</header>
			{isExamStarted ? (
				<button className="btn-save" onClick={saveResult}>
					Salvare rezultat
				</button>
			) : (
				<button className="btn-start" onClick={startChestionar}>
					Începe chestionar
				</button>
			)}
			<div className="exam-wrapper">
				{isExamStarted && (
					<iframe
						id="chestionar"
						width={`${(width * 75) / 100}px`}
						height="1300px"
						src="https://chestionare-az.ro/chestionare-auto-drpciv/"
					></iframe>
				)}
			</div>
			<Modal isOpen={isOpenModal} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
				<div className="modal-header">Atenție!</div>
				<div className="modal-content">Nu veți mai putea face modificari examenului!</div>
				<div className="modal-footer">
					<button id="modify-btn" className="btn" onClick={closeModal}>
						Mai am de modificat
					</button>
					<button id="save-btn" className="btn" onClick={printResult}>
						Salvează rezultatul
					</button>
				</div>
			</Modal>
			<footer>examenteoretic.teodrivedefensive.ro</footer>
		</div>
	);
}

export default Main;
